import { render, staticRenderFns } from "./registrationAdd.vue?vue&type=template&id=491a5c82&scoped=true&"
import script from "./registrationAdd.vue?vue&type=script&lang=js&"
export * from "./registrationAdd.vue?vue&type=script&lang=js&"
import style0 from "./registrationAdd.vue?vue&type=style&index=0&id=491a5c82&prod&scoped=true&lang=css&"
import style1 from "./registrationAdd.vue?vue&type=style&index=1&id=491a5c82&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "491a5c82",
  null
  
)

export default component.exports